<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import type { ContentSplitWithImageStoryblok } from '~/types/storyblok'
import { blokLink } from '~/helpers/blokLink'
import StoryblokRichtext from '~/components/Global/StoryblokRichtext.vue'
import UiLinkButton from '~/components/Ui/UiLinkButton.vue'

const props = defineProps<{
  blok: ContentSplitWithImageStoryblok
  printable?: boolean
}>()

const headingTag = useStoryblokUi().headingTag(props.blok.heading_tag)
const buttonVariant = useStoryblokUi().buttonVariant(props.blok.button_variant)
</script>

<template>
  <div
    v-editable="blok"
    class="grid break-inside-avoid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-8 print:grid-cols-2 print:gap-x-8">
    <div>
      <NuxtImg
        v-if="blok.image?.filename"
        :src="blok.image.filename"
        :alt="blok.image.alt"
        format="webp"
        provider="storyblok"
        sizes="500px lg:1200px"
        class="w-full rounded-lg" />
    </div>
    <div
      class="flex flex-col justify-center"
      :class="{
        'lg:order-first lg:pl-[130px] print:order-first': blok.image_position_right,
        'lg:pr-[130px]': !blok.image_position_right,
      }">
      <component :is="headingTag" class="mb-4 text-3xl font-semibold">{{ blok.heading }}</component>
      <StoryblokRichtext v-if="blok.text" :text="blok.text" />
      <div v-if="!printable && blok.button_to && blok.button_to.cached_url">
        <UiLinkButton :to="blokLink(blok.button_to)" class="group mt-8 text-sm" :variant="buttonVariant">
          {{ blok.button_text ? blok.button_text : blok.button_to.cached_url }}
          <template #suffix>
            <ChevronRightIcon class="ml-4 w-4 transition-transform duration-300 ease-out group-hover:translate-x-1" />
          </template>
        </UiLinkButton>
      </div>
    </div>
  </div>
</template>
